.snackbar {
  border-radius: 5px !important;

  &.error {
    background-color:#F44336 !important {} }

  span {
    display: flex;
    align-items: center; }

  svg {
    padding: 0 10px 0 0; } }
